import { version } from '../version';
import { config, storageKeys } from './config';
import { routes } from './routes';

export const environment = {
  production: false,
  staging: false,
  test: false,
  version,
  config,
  storageKeys,
  routes,
  apiBaseUrl: 'https://api-develop.conecta-facens.ligafacens.com.br',
  sentry: {
    environment: 'local',
    dsn: 'https://ed3b928ebdd049a3ab976805eed01f79@sentry.ligafacens.com/107',
    release: version,
  },
  wordpress: {
    base: 'https://conecta.facens.br',
    showcase: '/vitrine',
    categories: '/conexoes',
  },
  ipfacens: {
    base: 'https://ipfacens.com.br/',
    contact: '/contato',
  },
  firebase: {
    apiKey: 'AIzaSyCLwiv9Wl9SUNmvhVVyRwrvCeL45Io-Xm8',
    authDomain: 'development-conectafacens.firebaseapp.com',
    projectId: 'development-conectafacens',
    storageBucket: 'development-conectafacens.appspot.com',
    messagingSenderId: '107601520469',
    appId: '1:107601520469:web:a790869f62e7fa1e7431dc',
    measurementId: 'G-6JEVEFDSLD',
  },
};
